import { Box, Container, Grid } from "@mui/material";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import PersonalDetailsForm from "./forms/PersonalDetailsForm";
import Stepper from "../../../generic-components/stepper";
import {
  TRACKING_NAF_OPTIONS,
  TRACKING_NAF_STARTED_OPTIONS,
  wizardSteps,
} from "../../../../constants";
import FinalSummary from "./acknowledgement-and-signature/FinalSummary";
import AcknowledgementAndSignature from "./acknowledgement-and-signature/AcknowledgementAndSignature";
import SharedCostForm from "./forms/SharedCostForm";
import InvestmentChoicesStep2 from "./investment-choices/InvestmentChoicesStep2";
import { useNavigate, useSearchParams } from "react-router-dom";
import useStepper from "../../../../hooks/useStepper";
import usePlan from "../../../../hooks/usePlan";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import PensionFund from "./investment-choices/PensionFund";
import useUserEvent from "../../../../hooks/useUserEvent";
import savcFormBackground from "../../../../assets/images/savc-form-background.jpeg";
import Loader from "../../../generic-components/loader";
import moment from "moment";
import ExistingPrudentialAvcPlan from "./investment-choices/ExistingPrudentialAvcPlan";
import ExistingPrudentialPensionPlan from "./investment-choices/ExistingPrudentialPensionPlan";
import {
  createPersonalDetails,
  updateUserInfo,
} from "../../../../graphql/mutations/personalDetails";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setForm } from "../../../../reducers/formReducer";
import isEmpty from "lodash.isempty";
import { setUser } from "../../../../reducers/userReducer";
import { organisationRequiredDetails } from "../../../../graphql/queries/OrganisationDetails";
import {
  checkActiveSchemes,
  checkOrganisationDetailsValidation,
  legacyAppRedirection,
} from "../../../../helpers";
import AmountToContributeForm from "./forms/AmountToContributeForm";
import ProviderSelection from "./investment-choices/ProviderSelection";
import OpenFundChoices from "./investment-choices/OpenFundChoices";
import InvestmentChoices from "./investment-choices/InvestmentChoicesNew";
import FundChoices from "./investment-choices/FundChoices";
import InvestmentChoiceFundSelection from "./investment-choices/InvestmentChoiceFundSelection";
import GenericBack from "../../../generic-components/generic-back-button";

const SharedCostApplicationPage = ({
  organisation,
  user,
  sharedTitle,
  scheme,
}) => {
  const {
    activeStep,
    setActiveStep,
    sharedFormIndex,
    setSharedFormIndex,
    investmentFormIndex,
    setInvestmentFormIndex,
    submitFormIndex,
    setSubmitFormIndex,
    personalDetails,
    setPersonalDetails,
    trackingCode,
    setSaveError,
    setErrorToast,
    formValues,
  } = useStepper();
  const { isLatestActivePlan, plans } = usePlan();
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [createApplication] = useMutation(createPersonalDetails);
  const [updateUserDetails] = useMutation(updateUserInfo);
  const [checkOrganisationRequiredDetails] = useLazyQuery(
    organisationRequiredDetails
  );

  const ningiJourney = organisation?.ningi_journey;

  const { userTrackingMutation } = useUserEvent();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const renderComponent = () => {
    if (!ningiJourney) return <InvestmentChoicesStep2 />;

    // Handle the special case for index 4 first
    if (investmentFormIndex === 4) {
      return formValues?.previousEmployerPlan === "Yes" ? (
        <ExistingPrudentialAvcPlan />
      ) : (
        <OpenFundChoices />
      );
    }
    const componentMap = {
      1: <PensionFund />,
      2: <ProviderSelection />,
      3: <ExistingPrudentialPensionPlan />,
      5: <InvestmentChoices />,
      6: <FundChoices />,
      7: <InvestmentChoiceFundSelection />,
    };

    return componentMap[investmentFormIndex] || <InvestmentChoicesStep2 />;
  };

  const renderSteps = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <PersonalDetailsForm />;
      case 1:
        return (
          <>
            {sharedFormIndex === 1 ? (
              <SharedCostForm />
            ) : (
              <AmountToContributeForm />
            )}
          </>
        );

      case 2:
        return renderComponent();

      case 3:
        return (
          <>
            {submitFormIndex === 1 ? (
              <FinalSummary
                user={user}
                navigateToSharedCostAvc={navigateToSharedCostAvc}
                navigateToInvestmentChoices={navigateToInvestmentChoices}
                navigateToPersonalDetails={navigateToPersonalDetails}
              />
            ) : (
              <AcknowledgementAndSignature />
            )}
          </>
        );

      default:
        break;
    }
  }, [
    ningiJourney,
    activeStep,
    sharedFormIndex,
    investmentFormIndex,
    submitFormIndex,
  ]);

  const navigateToSharedCostAvc = () => {
    setActiveStep(1);
    setSharedFormIndex(1);
    setInvestmentFormIndex(1);
    setSubmitFormIndex(1);
  };

  const navigateToInvestmentChoices = () => {
    setActiveStep(2);
    setInvestmentFormIndex(3);
    setSharedFormIndex(1);
    setSubmitFormIndex(1);
  };
  const navigateToPersonalDetails = () => {
    setActiveStep(0);
    setInvestmentFormIndex(1);
    setSharedFormIndex(1);
    setSubmitFormIndex(1);
  };

  useEffect(() => {
    if (plans.length && isLatestActivePlan) {
      navigate("/manage_my_shared_cost_avc");
    }
  }, [plans, isLatestActivePlan]);

  useEffect(() => {
    if (organisation.id && !organisation.new_form) {
      legacyAppRedirection(
        personalDetails?.sub_domain,
        `/users/${personalDetails?.id}/new_scavc_application`
      );
    }
  }, [organisation]);

  const handleUserEvent = () => {
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_STARTED_OPTIONS,
        field_name: "Started",
        field_value: "",
        avc_track_code: trackingCode,
      },
    });
  };

  const getOrganisationRequiredDetails = async () => {
    checkOrganisationRequiredDetails({
      variables: {
        organisation_id: parseInt(organisation?.id),
      },
      onCompleted: (data) => {
        const payPeriods = data?.pay_periods?.length;
        const pensionFunds = data?.pension_funds?.length;
        const providers = data?.providers?.length;

        if (!payPeriods || !pensionFunds || !providers) {
          const warningMessage = checkOrganisationDetailsValidation(
            payPeriods,
            pensionFunds,
            providers
          );
          navigate("/home", {
            state: { warningMessage: warningMessage },
          });
        }
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const checkRequiredActiveSchemes = (personalDetails) => {
    const requiredActiveScheme = checkActiveSchemes(personalDetails);

    if (!requiredActiveScheme) {
      navigate("/scheme_not_found");
    }
  };

  const handlePlanCreation = async () => {
    if (personalDetails?.latest_temp_plan || isLatestActivePlan) {
      setLoading(false);
      return;
    }

    const employeeDetails = {
      title: personalDetails.title,
      gender: personalDetails.gender,
      employee_number: personalDetails.employee_number?.trim(),
      delivery_address1: personalDetails.address1,
      delivery_address2: personalDetails.address2,
      delivery_county: personalDetails.county,
      date_of_birth: personalDetails.date_of_birth || null,
      email: personalDetails.email,
      first_name: personalDetails.first_name,
      last_name: personalDetails.last_name,
      mobile_number: personalDetails.mobile_number,
      ni_number: personalDetails.ni_number,
      delivery_postcode: personalDetails.postcode,
      delivery_town: personalDetails.town,
      telephone_number: personalDetails.mobile_number,
      incomplete_application_at: moment().format(),
    };

    if (
      personalDetails.organisation_id &&
      scheme.scheme_type_id &&
      scheme.scheme_id
    ) {
      handleUserEvent();
      createApplication({
        variables: {
          scheme_id: parseInt(scheme.scheme_id),
          scheme_type_id: parseInt(scheme.scheme_type_id),
          user_id: personalDetails.id,
          total_avc_amount_requested:
            Number(searchParams.get("avc_amount")) || 0,
          organisation_id: parseInt(personalDetails.organisation_id),
          step_number: 0,
          tracking_code: trackingCode,
          created_at: moment().format(),
          updated_at: moment().format(),
          ...employeeDetails,
        },
        onCompleted: (data) => {
          dispatch(setForm(data.insert_temp_plans.returning[0]));
          setPersonalDetails((prevValues) => ({
            ...prevValues,
            latest_temp_plan: data.insert_temp_plans.returning[0].id,
          }));
          dispatch(
            setUser({
              latest_temp_plan: data.insert_temp_plans.returning[0].id,
            })
          );
          updateUserDetails({
            variables: employeeDetails,
            onCompleted: () => {
              setLoading(false);
            },
            onError: (error) => {
              setLoading(false);
              setSaveError(error);
              setErrorToast(true);
            },
          });
        },
        onError: (error) => {
          setLoading(false);
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else {
      setLoading(false);
      setSaveError({ type: "error" });
      setErrorToast(true);
    }
  };

  useEffect(() => {
    if (organisation?.id) {
      getOrganisationRequiredDetails();
    }
  }, [organisation]);

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      checkRequiredActiveSchemes(personalDetails);
    }
  }, [personalDetails]);

  useEffect(() => {
    if (!isEmpty(personalDetails) && !isEmpty(scheme)) {
      handlePlanCreation();
    }
  }, [personalDetails, scheme]);

  return (
    <>
      {isLoading ? (
        <Box className="flex-box-center full-width mt-250 mb-250">
          <Loader />
        </Box>
      ) : (
        <Box className="stepper-component">
          <SharedHeader
            subheading={`New ${sharedTitle} Application`}
            heading="Application Form"
          />
          <Box className="space-from-top">
            <Container>
              <Box className="stepper-layout">
                <GenericBack trackingDetails={TRACKING_NAF_OPTIONS} />
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={4.5} className="stepper-grid">
                    <Box
                      className="stepper-container"
                      style={{ backgroundImage: `url(${savcFormBackground})` }}
                    >
                      <Stepper steps={wizardSteps()} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={7.5} sx={{ marginBottom: 10 }}>
                    {activeStep === wizardSteps().length ? (
                      navigate("/thankyou")
                    ) : (
                      <>
                        <Box className="application-form">{renderSteps()}</Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
};

SharedCostApplicationPage.propTypes = {
  organisation: PropTypes.object,
  scheme: PropTypes.object,
  user: PropTypes.object,
  sharedTitle: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    organisation: state.organisation.organisation,
    user: state.user.user,
    scheme: state.scheme.scheme,
    sharedTitle: state.multiForm?.sharedTitle,
  };
};

export default connect(mapStateToProps)(SharedCostApplicationPage);
