import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import isEmpty from "lodash.isempty";
import IconButton from "../../../../generic-components/icon-btn";
import LoadingButton from "../../../../generic-components/button";
import CloseIcon from "@mui/icons-material/Close";
import StandardAVCDialog from "../../home-page/home-page-content/StandardAVCDialog";
import {
  TRACKING_NAF_OPTIONS,
  TRACKING_HOME_OPTIONS,
  dialogName,
} from "../../../../../constants";
import {
  checkActiveSchemes,
  legacyAppRedirection,
} from "../../../../../helpers";
import useStepper from "../../../../../hooks/useStepper";
import { getStandardAVCPlan } from "../../../../../graphql/queries/manageMySharedCost";
import { schemeTypeNames } from "../../../../../constants/multiForm";
import useDialog from "../../../../../hooks/useModal";

const HomePageButtons = ({ handleClose, organisation, user }) => {
  const { dialogs, openDialog, closeDialog } = useDialog();
  const [standardAVCScheme, setStandardAVCScheme] = useState(null);
  const [isAlreadyApplySAVC, setIsAlreadyApplySAVC] = useState(null);
  const [fetchStandardAVCPlan] = useLazyQuery(getStandardAVCPlan);
  const navigate = useNavigate();
  const { personalDetails, setSaveError, setErrorToast } = useStepper();

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      handleActiveSchemes();
    }
  }, [personalDetails]);

  const handleRedirectionToApplication = () => {
    if (organisation?.new_form) {
      navigate("/new-application-form");
      return;
    }
    legacyAppRedirection(
      personalDetails?.sub_domain,
      `/users/${user.id}/new_scavc_application`
    );
  };

  const handleConvertExisting = () => {
    legacyAppRedirection(
      personalDetails?.sub_domain,
      `/users/${user.id}/convert_existing_avc`
    );
  };

  const handleActiveSchemes = () => {
    if (!checkActiveSchemes(personalDetails)) {
      navigate("/scheme_not_found");
      return;
    }
    const standardAVC = personalDetails?.active_schemes?.find(
      (scheme) =>
        scheme?.scheme_type_name?.toLowerCase() ===
        schemeTypeNames?.STANDARD_AVC?.toLowerCase()
    );
    setStandardAVCScheme(standardAVC);
    getStandardAVCPlanDetails();
  };

  const getStandardAVCPlanDetails = () => {
    fetchStandardAVCPlan({
      fetchPolicy: "no-cache",
      variables: { user_id: personalDetails?.id },
      onCompleted: (data) =>
        setIsAlreadyApplySAVC(
          data?.cs_prior_user_details_aggregate?.aggregate?.count
        ),
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };
  return (
    <>
      <Box className="button-modal">
        <Stack direction="column" sx={{ p: 2 }}>
          <IconButton
            trackingDetails={TRACKING_NAF_OPTIONS}
            extraStyles={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            handleClick={handleClose}
            icon={<CloseIcon />}
          />
          <Stack direction="column" padding="1rem">
            <Stack direction="row" spacing={2} className="header-container">
              <Box className="header-sideline"></Box>
              <Typography className="eader-heading text-bold">
                Let’s get started
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              padding="1.4rem"
              alignItems="flex-start"
            >
              <Typography className="text-bold-lg" align="left">
                Do you already pay into an AVC?
              </Typography>
              <Stack
                spacing={1}
                padding="1rem"
                sx={{
                  width: "100%",
                }}
                alignItems="flex-start"
                className="bg-light-purple"
              >
                <Typography className="text-bold" align="left">
                  I don’t have an AVC
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  className="home-typography"
                >
                  That’s great - we’ll help you to apply for one.
                </Typography>
                <LoadingButton
                  buttonTitle="Start Application"
                  trackingDetails={TRACKING_HOME_OPTIONS}
                  handleClick={handleRedirectionToApplication}
                  styleClass={
                    "book-place-btn apply-now-btn bg-purple-text-white"
                  }
                />
              </Stack>
              <Stack
                spacing={1}
                padding="1rem"
                sx={{
                  width: "100%",
                }}
                alignItems="flex-start"
              >
                <Typography
                  variant="subtitle1"
                  className="text-bold"
                  align="left"
                >
                  I pay into a Standard AVC
                </Typography>
                <Typography align="left" className="home-typography">
                  You’re already investing in your future. <br />
                  We can convert from a{" "}
                  <span className="italic-font">Standard</span> to a{" "}
                  <span className="italic-font">Shared Cost AVC</span> so you
                  can make the most of the extra benefits.
                </Typography>
                <LoadingButton
                  buttonTitle="Convert my AVC"
                  trackingDetails={TRACKING_HOME_OPTIONS}
                  handleClick={handleConvertExisting}
                  styleClass={"book-place-btn"}
                />
              </Stack>
              <Stack
                spacing={1}
                padding="1rem"
                sx={{
                  width: "100%",
                }}
                alignItems="flex-start"
              >
                <Typography
                  variant="subtitle1"
                  className="text-bold"
                  align="left"
                >
                  I’m not sure
                </Typography>
                <Typography align="left" className="home-typography">
                  Don't worry if you're not sure. We'll walk you through the
                  application. <br /> If you do have one already, we'll help you
                  to convert it later.
                </Typography>
                <LoadingButton
                  buttonTitle="Help me to apply"
                  trackingDetails={TRACKING_HOME_OPTIONS}
                  handleClick={handleRedirectionToApplication}
                  styleClass={"book-place-btn"}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {!standardAVCScheme ? (
          <Stack
            direction="column"
            className="standard-avc-box"
            alignItems="flex-start"
            sx={{
              px: 8,
              py: 2,
              overflowY: "auto",
              height: "auto",
            }}
          >
            <Typography variant="subtitle1" className="text-bold" align="left">
              I want a Standard AVC instead
            </Typography>
            <Typography align="left" className="home-typography mt-10">
              A Standard AVC will only get you savings in Income Tax. <br /> You
              can apply for one, but you’ll save more with a Shared Cost AVC.
            </Typography>
            <Box onClick={() => openDialog(dialogName.STANDARD_AVC_1)}>
              <Typography
                sx={{ textDecoration: "underline" }}
                className="text-bold mt-10"
              >
                Apply for Standard AVC
              </Typography>
            </Box>
          </Stack>
        ) : null}
      </Box>
      <StandardAVCDialog
        open={dialogs?.includes(dialogName.STANDARD_AVC_1)}
        onClose={() => closeDialog(dialogName.STANDARD_AVC_1)}
        standardAVCScheme={standardAVCScheme}
        isAlreadyApplySAVC={isAlreadyApplySAVC}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
  };
};

HomePageButtons.propTypes = {
  organisation: PropTypes.object,
  handleClose: PropTypes.func,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(HomePageButtons);
